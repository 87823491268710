"use strict";
const _ = require("underscore");

module.exports = [
    "$scope",
    "residents.service",
    "notification.service",
    "$state",
    "dialog.service",
    "fundingService",
    "facility.service",
    function ($scope, residentsService, notification, $state, dialogService, anaccService) {
        var vm = this;
        var residentId = $scope.residentId;
        var stateViewResidentFinancialsFunding = "viewResident.financialsFunding";
        var stateViewLeadFinancialsFunding = "viewLead.financialsFunding";

        vm.resident = {};
        angular.extend(vm.resident);

        vm.supplementStatusBackup = {
            'pensionerSupplement': null,
            'concessionalSupplement': null,
            'veteranSupplement': null
        };
        
        function initializeFinanceInformation() {
            vm.isResidentCareTypeRespite = vm.finance.residentCareType === "Respite";
            vm.isResidentCareTypePermanent = vm.finance.residentCareType === "Permanent";
            
            if (!vm.finance.respiteCareLevel) {
                vm.finance.respiteCareLevel = "Unknown";
            }

            if (!vm.finance.supportLevel) {
                vm.finance.supportLevel = "Unknown";
            }

            vm.isUnknown = function () { return vm.finance.supportLevel === "Unknown" };
            vm.isSupported = function () { return vm.finance.supportLevel === "Supported" };
            vm.isUnsupported = function () { return vm.finance.supportLevel === "Unsupported" };
            vm.showDac = function () { return vm.isSupported() };
            vm.showAgreedRoomPrice = function () { return vm.isUnknown() || vm.isUnsupported() };
            vm.displayAnaccSection = $state.current.name === stateViewResidentFinancialsFunding;
            vm.isSupplementStatusInEdit = false;
            vm.calculateDailyBasicSubsidy();

            // Instead of doing this, maybe return the rates in the funding dto???
            anaccService.getAnaccFixedComponentForCurrentFacility().then(result => {
                vm.fixedComponent = result;
                vm.dailyTotal = vm.calculateDailyBasicSubsidy();
            }, () => {
                notification.error("Unexpected error while loading MMM classification.");
                vm.fixedComponent = null;
            });

            vm.variableComponent = vm.finance.anaccClassification?.anaccVariableComponent ?? null;
            vm.variableComponentText = vm.variableComponent?.anaccClassText.replace("Class ", "") ?? null;
            vm.dailyTotal = vm.calculateDailyBasicSubsidy();
        }

        vm.editSupplementStatus = () => {
            vm.isSupplementStatusInEdit = true;
            vm.supplementStatusBackup.pensionerSupplement = vm.finance.pensionerSupplement;
            vm.supplementStatusBackup.concessionalSupplement = vm.finance.concessionalSupplement;
            vm.supplementStatusBackup.veteranSupplement = vm.finance.veteranSupplement;
        }

        vm.cancelSupplementSatusEdit = () => {
            vm.isSupplementStatusInEdit = false;
            vm.finance.pensionerSupplement = vm.supplementStatusBackup.pensionerSupplement;
            vm.finance.concessionalSupplement = vm.supplementStatusBackup.concessionalSupplement;
            vm.finance.veteranSupplement = vm.supplementStatusBackup.veteranSupplement;
        }

        vm.saveSupplementStatus = () => {
            residentsService.saveResidentFinanceFundingSupplementStatus(vm.finance.residentId, vm.finance.pensionerSupplement, vm.finance.concessionalSupplement, vm.finance.veteranSupplement).then(() => {
                vm.isSupplementStatusInEdit = false;
                notification.success("Changes have been saved.");
            }, () => {
                notification.error("Unable to save supplement status. Either your computer is offline, or Resident Manager is having problems of its own.");
            });
        }

        function displayAcfiSubmissionErrors(modelState) {
            var dialogHeader = "ACFI not sent";
            if (!modelState) {
                notification.error("The ACFI file could not be sent. Either your computer is offline, or Resident Manager is having problems of its own.");
            } else if (modelState.acfiXml) {
                dialogService.openMessageDialog(dialogHeader, modelState.acfiXml, "acfi-error");
            } else {
                dialogService.openMessageDialog(
                    dialogHeader,
                    "The following ACFI details do not match the resident’s record :",
                    "acfi-error",
                    _.values(modelState));
            }
        }

        residentsService.getResidentFinanceFunding(residentId).then(function (finance) {
            vm.finance = finance;
            initializeFinanceInformation();
        }, function (error) {
            if (error.status === 404) {
                vm.finance = {
                    supportLevel: "Unknown"
                }
            } else {
                notification.error("Unable to display financials. Either your computer is offline, or Resident Manager is having problems of its own.");
            }
        });

        $scope.uploadAcfi = function (files) {
            if (files.length === 0)
                return;

            vm.acfiSubmissionErrors = [];
            residentsService.submitAcfiForResident(residentId, files[0]).then(
                function () {
                    notification.success("ACFI sent to Medicare.");
                    $state.reload();
                },
                function (error) {
                    displayAcfiSubmissionErrors(error.modelStateSummary);
                }
            );
        };

        vm.assignExistingAcfi = function () {
            dialogService.openDialog("app/residents/partials/assignExistingACFIDialog.html", "assignExistingACFIDialog.controller", {
                resident: {
                    id: residentId,
                    careType: vm.finance.residentCareType,
                    firstName: vm.finance.residentFirstName,
                    lastName: vm.finance.residentLastName
                },
                existingAcfi: vm.finance.latestAcfi
            });
        };

        vm.recordANACCReassessmentRequest = function () {
            dialogService.openDialog("app/residents/partials/addAN-ACCReassessmentRequestDialog.html", "addAN-ACCReassessmentRequestDialog.controller", {
                resident: {
                    id: residentId,
                    careType: vm.finance.residentCareType,
                    firstName: vm.finance.residentFirstName,
                    lastName: vm.finance.residentLastName
                },
            });
        };

        vm.enterExpectedANACC = function () {
            let expectedAnaccClassification = vm.finance.anaccClassification?.isActual || vm.finance.anaccClassification?.anaccVariableComponent.isDefault ? null : vm.finance.anaccClassification;

            dialogService.openDialog("app/residents/partials/enterExpectedAN-ACCDialog.html", "enterExpectedAN-ACCDialog.controller", {
                resident: {
                    id: residentId,
                    careType: vm.finance.residentCareType,
                    firstName: vm.finance.residentFirstName,
                    lastName: vm.finance.residentLastName
                },
                expectedAnaccClassification
            });
        };

        vm.calculateDailyBasicSubsidy = function() {
            if (vm.fixedComponent && vm.variableComponent)
            {
                return vm.fixedComponent.baseCareTariff + vm.variableComponent.rate;
            }

            return null;
        }

        vm.save = function (isvalid) {
            if (isvalid) {
                residentsService.saveResidentFinanceFunding(residentId, vm.finance).then(
                    function () {
                        notification.success("Changes have been saved.");
                        vm.redirectToView();
                    },
                    function () {
                        notification.error("Unable to save changes. Either your computer is offline, or Resident Manager is having problems of its own.");
                    });
            }
        };

        vm.redirectToView = function () {
            if ($state.current.name === "editResident.financialsFunding") {
                $state.go(stateViewResidentFinancialsFunding, { "residentId": residentId });
            } else if ($state.current.name === "editLead.financialsFunding") {
                $state.go(stateViewLeadFinancialsFunding, { "residentId": residentId });
            }
        };

        vm.showFeesTab = function () {          
            switch ($state.current.name) {
                case "viewResident.financialsFunding":
                case "editResident.financialsFunding":
                    $state.go("viewResident.financialsFees", { "residentId": residentId, "isPrivateResidency":  false});
                    break;
                case "viewLead.financialsFunding":
                case "editLead.financialsFunding":
                    $state.go("viewLead.financialsFees", { "residentId": residentId, "isPrivateResidency":  false });
                    break;
            }
        };

        vm.showTransactionTab = function () {           
            switch($state.current.name) {                
                case "viewResident.financialsFunding":
                case "editResident.financialsFunding":
                    $state.go("viewResident.financialsTransaction", { "residentId": residentId, "isPrivateResidency":  false });
                    break;
                case "viewLead.financialsFunding":
                case "editLead.financialsFunding":
                    $state.go("viewLead.financialsTransaction", { "residentId": residentId, "isPrivateResidency":  false });
                    break;

            }
        };

        vm.navigateToEdit = function () {
            switch ($state.current.name) {
                case "viewResident.financialsFunding":
                    $state.go("editResident.financialsFunding", { residentId: residentId });
                    break;
                case "viewLead.financialsFunding":
                    $state.go("editLead.financialsFunding", { residentId: residentId });
                    break;
            }
        };

        vm.addSupplementOxygen = function () {
            dialogService.openDialog("app/residents/partials/residentSupplementsOxygenDialog.html", "residentSupplementsOxygenDialog.controller", {
                finance: vm.finance,
                mode: "add",
                residentId: residentId
            });
        };

        vm.editSupplementOxygen = function () {
            dialogService.openDialog("app/residents/partials/residentSupplementsOxygenDialog.html", "residentSupplementsOxygenDialog.controller", {
                finance: vm.finance,
                mode: "edit",
                residentId: residentId
            });
        };

        vm.endSupplementOxygen = function () {
            dialogService.openDialog("app/residents/partials/residentSupplementsOxygenDialog.html", "residentSupplementsOxygenDialog.controller", {
                finance: vm.finance,
                mode: "end",
                residentId: residentId
            });
        };

        vm.addSupplementEnteralFeeding = function () {
            dialogService.openDialog("app/residents/partials/residentSupplementsEnteralFeedingDialog.html", "residentSupplementsEnteralFeedingDialog.controller", {
                finance: vm.finance,
                mode: "add",
                residentId: residentId
            });
        };

        vm.editSupplementEnteralFeeding = function () {
            dialogService.openDialog("app/residents/partials/residentSupplementsEnteralFeedingDialog.html", "residentSupplementsEnteralFeedingDialog.controller", {
                finance: vm.finance,
                mode: "edit",
                residentId: residentId
            });
        };

        vm.endSupplementEnteralFeeding = function () {
            dialogService.openDialog("app/residents/partials/residentSupplementsEnteralFeedingDialog.html", "residentSupplementsEnteralFeedingDialog.controller", {
                finance: vm.finance,
                mode: "end",
                residentId: residentId
            });
        };

    }
];
