import * as _ from "underscore";
import moment = require("moment");
import { INotificationService } from "../../common/services/INotificationService";
import { IDialogService } from "../../common/services/IDialogService";
import { IOrganisationFacilityService } from "../services/IOrganisationFacilityService";
import { IFacilityService } from "../../authentication/services/IFacilityService";
import { GLAccountMapping, User, AccountMappingGroup, AccountMapping } from "../Models";

class ViewAndEditGLAccountMappingController {
  static $inject = ["organisation.facility.service", "notification.service", "dialog.service", "facility.service"];

  private mode: string;
  public glAccountMappings: Array<GLAccountMapping>;
  public accountMappingGroups: Array<AccountMappingGroup>;
  public subsidiesAccountMappingGroups: Array<AccountMappingGroup>;
  public defaultAccountMapping: GLAccountMapping;
  public selectedFacility: any;
  public isAddingSundryAccount: boolean;
  public facilityCode: string;
  public isSelectedFacilityBillingIntegrated: boolean;
  public selectedFacilityType: string;
  
  constructor(
    private organisationFacilityService: IOrganisationFacilityService,
    private notificationService: INotificationService,
    private dialogService: IDialogService,
    private facilityService: IFacilityService
  ) {
    this.mode = "view";
    this.getGLAccountMappings();
  }

  private getGLAccountMappings = () => {
    this.organisationFacilityService.getGLAccountMappings().then(
      (result) => {
        //initialise the global variables
        this.glAccountMappings = result;
        this.setSelectedFacility();
        this.setDefaultAccountMapping();
        this.getAccountMappingGroups();
      },
      () => {
        this.notificationService.error("Unexcpected error while loading account mapping.");
      }
    );
  };

  //set selected facility
  private setSelectedFacility = () => {
    this.selectedFacility = this.facilityService.selectedFacility;
    this.isSelectedFacilityBillingIntegrated = this.selectedFacility.isBillingIntegratedFacility;
    this.selectedFacilityType = this.selectedFacility.facilityType;
  };

  //Set default account mapping - this is displayed on the top
  private setDefaultAccountMapping = () => {
    this.defaultAccountMapping = _.find(this.glAccountMappings, { resmanValue: "DefaultAccount" });
  };

  //Get the distinct group list and respective account type for each group
  private getAccountMappingGroups = () => {
    let uniqueMappingGroups = _.uniq(this.glAccountMappings, false, (e) => e.mappingGroup);
    this.accountMappingGroups = new Array<AccountMappingGroup>();
    this.subsidiesAccountMappingGroups = new Array<AccountMappingGroup>();
    _.forEach(
      uniqueMappingGroups,
      function (item) {
        if (item.mappingGroup != "DefaultAccount") {
          var groupName = item.mappingGroup;
          var groupDesc = item.mappingGroupDescription;
          var showGroup = true;
          var showOnlyOptionalServicesInFeesAndCharges = false;
          var subsidieShowGroup = false;


          if (this.isSelectedFacilityBillingIntegrated) {
            if ((this.selectedFacilityType == "AgeCare" && groupName == "FeesAndCharges") || 
                (this.selectedFacilityType == "IndependentLiving" && groupName != "SundryCharges")) {
              showGroup = false;
            }
          } else {
            if (this.selectedFacilityType == "IndependentLiving" && groupName != "SundryCharges" && groupName != "FeesAndCharges") {
              showGroup = false;
            } else if (this.selectedFacilityType == "IndependentLiving" && groupName == "FeesAndCharges") {
              showOnlyOptionalServicesInFeesAndCharges = true;
            }
          }

          if ((this.selectedFacilityType == "AgeCare" && groupName == "ANACCPermanent") || 
                (this.selectedFacilityType == "AgeCare" && groupName == "ANACCRespite")) {
              showGroup = false;
              subsidieShowGroup = true;
          } else if ((this.selectedFacilityType == "IndependentLiving" && groupName == "ANACCPermanent") || 
          (this.selectedFacilityType == "IndependentLiving" && groupName == "ANACCRespite")) {
            showGroup = false;
            subsidieShowGroup = false;
          }


          if (showGroup) {
            this.accountMappingGroups.push(new AccountMappingGroup(groupName, groupDesc, this.getAccountMappings(groupName, showOnlyOptionalServicesInFeesAndCharges)));
          } else if (subsidieShowGroup) {
            this.subsidiesAccountMappingGroups.push(new AccountMappingGroup(groupName, groupDesc, this.getAccountMappings(groupName, showOnlyOptionalServicesInFeesAndCharges)));
          }

        }
      },
      this
    );

    //add the empty SundryCharge Group if no sundry charge exists
    var sundryGroup = _.find(this.accountMappingGroups, { groupHeader: "SundryCharges" });
    if (!sundryGroup) this.addEmptySundryChargeGroup();
  };

  private addEmptySundryChargeGroup = () => {
    this.accountMappingGroups.push(new AccountMappingGroup("SundryCharges", " Sundry Charges", this.getAccountMappings("SundryCharges")));
  };

  private isDuplicateSundryCharge = (type: string, indexofItem: number) => {
    let duplicateSundryCharge = false;
    _.find(this.accountMappingGroups, function (mappinGroup) {
      if (mappinGroup.groupHeader == "SundryCharges") {
        duplicateSundryCharge = _.some(mappinGroup.accountMappings, (mapping) => {
          return mapping.type.toUpperCase() == type.toUpperCase() && mappinGroup.accountMappings.indexOf(mapping) != indexofItem;
        });
      }
    });
    return duplicateSundryCharge;
  };

  //generate AccountMappings for the given group name
  private getAccountMappings = (group: string, showOnlyOptionalServicesInFeesAndCharges: boolean = false) => {
    var result = this.glAccountMappings.filter(function (item) {
      return item.mappingGroup == group;
    });

    let accountMappings = new Array<AccountMapping>();

    _.forEach(
      result,
      function (item) {
        let type = item.otherInfo;
        // This is special requirement to display the abbreviation with the detail description
        if (item.resmanValue == "RAD" || item.resmanValue == "RAC" || item.resmanValue == "DAP" || item.resmanValue == "DAC") type = item.resmanValue + " " + item.otherInfo;

        if (!showOnlyOptionalServicesInFeesAndCharges || (showOnlyOptionalServicesInFeesAndCharges && item.resmanValue == "OptionalServices")) {
          accountMappings.push(new AccountMapping(type, item.mappingValue, _.find(this.glAccountMappings, { resmanValue: item.resmanValue }), false));
        }
      },
      this
    );
    return accountMappings;
  };

  private AddAccountMappings = (group: string) => {
    let result = this.accountMappingGroups.filter(function (item) {
      return item.groupHeader == group;
    });

    if (result) {
      result[0].accountMappings.push(new AccountMapping("", "", undefined, true));
    }
  };

  //Save the Account Mapping value
  public saveGLAccountMapping = (isFormValid: boolean) => {
    if (!isFormValid) return;

    //Insert newly added sundrycharges to glAccountMapping collection
    var result = _.find(this.accountMappingGroups, (e) => e.groupHeader == "SundryCharges");
    if (result) {
      let newSundryCharges = _.filter(result.accountMappings, (e) => e.insertFlag == true);
      if (newSundryCharges) {
        _.forEach(
          newSundryCharges,
          function (item) {
            if (item.mapping) {
              var glAccountMapping = new GLAccountMapping();
              glAccountMapping.mappingGroup = "SundryCharges";
              glAccountMapping.mappingType = "GLAccountType";
              glAccountMapping.mappingValue = item.mapping.mappingValue;
              glAccountMapping.otherInfo = item.mapping.otherInfo;
              glAccountMapping.resmanValue = item.mapping.resmanValue;
              glAccountMapping.insertFlag = true;
              glAccountMapping.facilityId = this.selectedFacility.id;
              this.glAccountMappings.push(glAccountMapping);
            }
          },
          this
        );
      }
    }

    let isUnmapped = _.some(this.glAccountMappings, (mapping) => {
      return !mapping.mappingValue;
    });

    if (isUnmapped) {
      this.dialogService.openTemplateActionDialog("app/organisation/partials/unmappedAccountDialog.html", this.save);
    } else {
      this.save();
    }
  };

  public startAddingSundryAccount = (isFormValid: boolean) => {
    if (!isFormValid) return;

    this.isAddingSundryAccount = true;
    this.AddAccountMappings("SundryCharges");
  };

  private save = () => {
    this.organisationFacilityService.saveGLAccountMappings(this.glAccountMappings).then(
      (result) => {
        this.notificationService.success("Saved successfully.");
        this.mode = "view";
        this.getGLAccountMappings();
      },
      () => {
        this.notificationService.error("Error occurred while saving the account mapping. please try again.");
      }
    );
  };

  public cancel = () => {
    this.mode = "view";
    // refresh data after update is cancelled
    this.getGLAccountMappings();
  };

  public edit = () => {
    this.mode = "edit";
  };
}

export = ViewAndEditGLAccountMappingController;
